import React, { useState } from "react";
import Countdown from "react-countdown";
import { SocialIcon } from "react-social-icons";
import Axios from "axios";

import Modal from "./Modal";
import "./App.css"; // We'll use CSS for styling

const App = () => {
  const [m, setM] = useState("");
  const [n, setN] = useState("");
  const [p, setP] = useState("");
  const [msg, setMsg] = useState("None");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("None");
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [modalMessage, setModalMessage] = useState(""); // Modal message

  function timedReload() {
    setTimeout(() => {
      window.location.reload();
    }, [1500]);
  }

  function sendNewsLetterInfo() {
    if (m && n && p && msg) {
      setLoading(true);
      console.log(m);
      Axios.post(`https://api.bemylifecoach.com/api/contactform/newsletter`, {
        m,
        n,
        p,
        msg,
      })
        .then(() => {
          setLoading(false);
          setModalMessage("Successfully Submitted");
          setIsModalOpen(true);
          timedReload();
        })
        .then(() => {})
        .catch(() => {
          setLoading(false);
          setModalMessage("There was an error. Please try again.");
          setIsModalOpen(true);
          //alert("There was an Error");
        });
      return;
    }
    setModalMessage("Please fill all the fields.");
    setIsModalOpen(true);
  }

  function sendSignUpInfo() {
    console.log(email);
    if (email && name && phone && message) {
      Axios.post(`https://api.bemylifecoach.com/api/contactform/signup`, {
        email,
        name,
        phone,
        message,
      })
        .then(() => {
          setLoading(false);
          setModalMessage("Successfully Submitted");
          setIsModalOpen(true);
          timedReload();
        })
        .catch(() => {
          setLoading(false);
          setModalMessage("There was an error. Please try again.");
          setIsModalOpen(true); // Show modal
        });
      return;
    }
    setModalMessage("Please fill in all the fields.");
    setIsModalOpen(true);
  }

  return (
    <div className="container">
      <header>
        <img src="PNG.png" alt="logo" className="logo" />

        <h2>Coming Soon</h2>
        <p className="countdown">
          <Countdown date={Date.now() + 59 * 24 * 60 * 60 * 1000} />
        </p>

        <p className="textCont">
          Search, connect, and grow with Be My Life Coach. Our platform is
          designed to help you find the perfect life coach for your personal and
          professional growth. Coming soon—your journey to a better you starts
          here.
        </p>
      </header>

      <div className="both">
        <section className="signup">
          <h1 style={{ color: "white" }}>Life Coach Sign Up</h1>
          <p className="suP" style={{ color: "white" }}>
            <b>
              If you are a Certified Life Coach and want to join our life coach
              community
            </b>
          </p>
          <form>
            <div class="form-group">
              <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Name"
              />
            </div>
            <div class="form-group ">
              <input
                id="phone"
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter Phone"
              />
            </div>

            <div class="form-group full-width">
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email"
              />
            </div>

            <div class="form-group full-width">
              <textarea
                id="message"
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Your message here"
              ></textarea>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                sendSignUpInfo();
              }}
              type="button"
              className="btn1"
              disabled={loading}
            >
              {loading ? "Submitting...." : "Submit"}
            </button>
          </form>
        </section>

        <section className="newsletter section-2 ">
          <div class="para">
            <h2>
              Looking to speak to a Certified Life Coach before our site is up?
            </h2>
          </div>
          <div class="cont">
            <p class="ppp">Be an early bird and sign up here</p>
          </div>
          <form>
            <div class="form-group">
              <input
                id="name"
                type="text"
                value={n}
                onChange={(e) => setN(e.target.value)}
                placeholder="Enter Name"
              />
            </div>
            <div class="form-group ">
              <input
                id="phone"
                type="text"
                value={p}
                onChange={(e) => setP(e.target.value)}
                placeholder="Enter Phone"
              />
            </div>

            <div class="form-group full-width">
              <input
                id="email"
                type="email"
                value={m}
                onChange={(e) => setM(e.target.value)}
                placeholder="Enter Email"
              />
            </div>

            <div class="form-group full-width">
              <textarea
                id="message"
                onChange={(e) => setMsg(e.target.value)}
                placeholder="Your message here"
              ></textarea>
            </div>
            <button
              className="btn2"
              onClick={(e) => {
                e.preventDefault();
                sendNewsLetterInfo();
              }}
              type="button"
              disabled={loading}
            >
              {loading ? "Submitting...." : "Submit"}
            </button>
          </form>
          {/*<div className="form-group">
            <input
              id="mail"
              type="email"
              onChange={(e) => setMail(e.target.value)}
              placeholder="Enter your email"
            />
          </div>
          <button
            className="btn2"
            onClick={() => {
              sendNewsLetterInfo();
            }}
            type="submit"
          >
            Submit
          </button>*/}
        </section>
      </div>

      <div className="footer">
        <div>
          <h2>BE MY LIFE COACH</h2>

          <p>
            Be My Life Coach connects you with certified professionals ready to
            guide you through life's challenges. Our certified life coaches
            utilize a holistic approach, focusing on the mind, body, and spirit.
            We believe that true change comes from within, and our mission is to
            help clients discover their strengths, overcome obstacles, and
            cultivate resilience.
          </p>
        </div>

        <div>
          <h3>Follow us</h3>
          <div className="soc_icons">
            {/*<i className="fa-brands fa-facebook"></i>
            <i className="fa-brands fa-twitter"></i>
            <i className="fa-brands fa-linkedin"></i>*/}
            <SocialIcon
              network="facebook"
              bgColor="white"
              fgColor="black"
              url="https://www.facebook.com/profile.php?id=61565415402076&mibextid=LQQJ4d"
            />
            <SocialIcon
              network="instagram"
              bgColor="white"
              fgColor="black"
              url="https://www.instagram.com/bemylifecoaches?igsh=MWtuMzNrZmpudWtieA=="
            />
            <SocialIcon
              network="linkedin"
              bgColor="white"
              fgColor="black"
              url="https://www.linkedin.com/company/be-my-life-coach/about/"
            />
            <SocialIcon
              network="tiktok"
              bgColor="white"
              fgColor="black"
              url="https://www.tiktok.com/@be.my.life.coach?_t=8qdOqGsq3UU&_r=1"
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)} // Close the modal
        message={modalMessage}
      />
    </div>
  );
};

export default App;
