import React from "react";
import "./Modal.css"; // You can style the modal as needed

const Modal = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>{message}</p>
        {message !== "Successfully Submitted" && (
          <button onClick={onClose}>Close</button>
        )}
      </div>
    </div>
  );
};

export default Modal;
